import { Phone, Clock, MapPin, Mail } from "lucide-react";
import { Map } from "./Map";
import React from "react";

export function Contact() {
    const contactInfo = [
        { icon: Phone, text: "Fijo: 981 860 425", link: "tel:+34981 860 425" },
        { icon: Phone, text: "Teléfono: 604 025 528", link: "tel:+34604 025 528" },
        { icon: Mail, text: "Email: online@farmaciaprada.com", link: "mailto:online@farmaciaprada.com" },
        { icon: Clock, text: "Lunes-Viernes: 9h-21h, Sábado: 9:30h-14h" },
        {
            icon: MapPin,
            text: "Dirección: Plaza Rafael Dieste, 6, 15920, Rianxo, A Coruña, España",
            link: "https://maps.app.goo.gl/jVuXkxYkcP89Cigu8"
        },
    ];

    return (
        <section id="contact" className="w-full py-12 md:py-24">
            <div className="container px-4 md:px-6 mx-auto">
                <div className="grid gap-10 lg:grid-cols-2">
                    {/* Información de Contacto */}
                    <div className="space-y-6">
                        <div className="inline-block rounded-lg bg-muted px-3 py-1 text-sm">Habla con nosotros</div>
                        <h2 className="text-3xl font-bold tracking-tighter md:text-4xl text-[var(--primary)]">Contacto</h2>
                        <p className="text-muted-foreground md:text-xl">Si necesitas ayuda, aquí estamos:</p>
                        <div className="grid gap-3">
                            {contactInfo.map(({ icon: Icon, text, link }, index) => (
                                <div key={index} className="flex items-center gap-3">
                                    <Icon className="h-5 w-5 text-primary" aria-hidden="true" />
                                    {link ? (
                                        <a className="hover:text-primary transition" href={link} target="_blank" rel="noopener noreferrer">
                                            {text}
                                        </a>
                                    ) : (
                                        <span>{text}</span>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Mapa */}
                    <div className="aspect-video overflow-hidden rounded-lg">
                        <div className="inline-block rounded-lg bg-muted px-3 py-1 mb-2 text-sm">Cómo llegar</div>
                        <div className="h-full w-full bg-muted flex items-center justify-center">
                            <Map />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
