import "./App.css"
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom"
import { Home } from "./sections/Home"
import { Footer } from "./components/Footer"
import { Map } from "./sections/Map"
import { Header } from "./components/Header"
import { Services } from "./sections/Services"
import { Contact } from "./sections/Contact"
import { Error404 } from "./sections/Error404"
import { Guardias } from "./sections/Guardias"
import { useRef } from "react"

function Layout() {

  return (
    <div className="flex min-h-screen flex-col">
      <Header />
      <div className="flex-1 w-full pt-16">
        <Routes>
          <Route path="*" element={<Error404 />} />
          <Route
            path="/"
            element={
              <div className="flex flex-col">
                <div className="flex-none">
                  <Home />
                </div>
                <div className="flex-1">
                  <Services />
                  <Contact />
                </div>
              </div>
            }
          />
          <Route path="/map" element={<Map />} />
          <Route path="/guardias" element={<Guardias/>}/>
        </Routes>
      </div>
      <Footer />
    </div>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  )
}

export default App

