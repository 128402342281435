import { Button } from "@/components/ui/button"
import { PillIcon, HomeIcon, ArrowLeftIcon } from "lucide-react"
import { Link } from "react-router-dom"

export function Error404() {
    return (
        <div className="flex flex-col items-center justify-center min-h-[70vh] px-4 py-12 text-center">
            <div className="mb-6">
                <PillIcon className="h-24 w-24 text-teal-500 mx-auto animate-pulse" />
            </div>

            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl mb-2">404</h1>

            <h2 className="text-2xl font-semibold text-gray-700 mb-4">Página no encontrada</h2>

            <p className="text-lg text-gray-600 max-w-md mb-8">
                El medicamento que estás buscando parece estar agotado o no existe en nuestra farmacia.
            </p>

            <div className="flex flex-col sm:flex-row gap-4">
                <Button asChild variant="default" className="hover:bg-primary bg-secondary">
                    <Link to="/">
                        <HomeIcon className="mr-2 h-4 w-4" />
                        Return to Homepage
                    </Link>
                </Button>

                
            </div>
        </div>
    )
}

