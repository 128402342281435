

import { Button } from "@/components/ui/button"
import { Phone, Clock, MapPin, CalendarDays } from 'lucide-react'
import Hero from "@/assets/hero.webp"
import { Link } from "react-router-dom"


export function Home() {
  return (
    <main id="home" className="flex-1">
      {/* Espacio para evitar que el header fijo oculte contenido */}
      <div className="h-16"></div>

      {/* Hero Section - Más grande y con más espacio */}
      <section className="w-full py-12 md:py-20 lg:py-24">
        <div className="container px-4 md:px-6 mx-auto">
          <div className="grid gap-10 lg:grid-cols-2 lg:gap-16 items-center">
            {/* Texto - Aumentado tamaño y espaciado */}
            <div className="space-y-6 text-center lg:text-left">
              <div className="inline-block rounded-lg bg-primary/10 px-4 py-1.5 text-base text-primary">
                Tu Salud, Nuestra Prioridad
              </div>
              <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl">
                Farmacia de Confianza en tu Comunidad
              </h1>
              <p className="max-w-[600px] mx-auto lg:mx-0 text-muted-foreground text-lg md:text-xl">
                Proveemos medicamentos de calidad, asesoramiento experto y atención personalizada para ti y tu familia.
              </p>
              <div className="flex justify-center lg:justify-start pt-2">
                <a href="#contact" aria-label="Contáctanos">
                  <Button className="rounded-full px-8 py-6 text-lg h-auto">
                    Contáctanos
                  </Button>
                </a>
              </div>
            </div>

            {/* Imagen - Más grande */}
            <div className="flex justify-center">
              <img
                src={Hero || "/placeholder.svg"}
                alt="Imagen de la farmacia"
                className="rounded-lg object-contain w-full max-w-[600px] h-auto shadow-xl"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Info Bar - Más espaciada */}
      <section className="info-bar w-full py-6 bg-primary text-primary-foreground">
        <div className="container px-4 md:px-6 mx-auto">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4 md:gap-6">
            <div className="flex flex-row items-center gap-4">
              <Clock className="h-8 w-8 flex-shrink-0" />
              <div>
                <p className="font-semibold text-base">Horario</p>
                <p className="text-xl">Lunes-Viernes: 9h-21h, Sábado: 9:30h-14h</p>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4">
              <Phone className="h-8 w-8 flex-shrink-0" />
              <div>
                <p className="font-semibold text-base">Contáctanos</p>
                <a href="tel:+34981860425" className="text-xl block">
                  Fijo: 981 860 425
                </a>
                <a href="tel:+34604025528" className="text-xl block">
                  Teléfono: 604 025 528
                </a>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4">
              <MapPin className="h-8 w-8 flex-shrink-0" />
              <div>
                <p className="font-semibold text-base">Ubicación</p>
                <p className="text-xl">Plaza Rafael Dieste, 6, 15920, Rianxo, A Coruña, España</p>
              </div>
            </div>
            <div className="flex flex-row items-center gap-4">
              <CalendarDays className="h-8 w-8 flex-shrink-0" />
              <div>
                <Link to="/guardias" target="_blank" rel="noreferrer">
                  <p className="font-semibold text-base">Guardias</p>
                  <p className="text-xl">Ver nuestras guardias aquí</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
