import { Link } from "react-router-dom";
import Guardia from "../assets/GUARDIAS.webp";
import PDF from "../assets/GUARDIAS.pdf";
import { Button } from "@/components/ui/button";
import Nombre from "@/assets/nombre.webp";

export function Guardias() {
    return (
        <section className="w-full py-6 bg-[#ffffff] text-primary-foreground ">
            
            <div className="container mx-auto flex justify-center px-4 md:px-6 mt-4">
                <img
                    src={Guardia}
                    alt="Guardias"
                    className="rounded-lg object-contain max-w-full md:max-w-[600px] h-auto shadow-xl"
                />
            </div>
            <div className="flex flex-wrap items-center justify-center gap-4 mt-10">
                <Button variant="secondary" className="w-full sm:w-80 h-12 text-white font-bold text-xl">
                    <a href={PDF} download>Descargar Guardias</a>
                </Button>
                
            </div>
        </section>
    );
}
