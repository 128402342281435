import { Menu, X } from "lucide-react";
import logo from "@/assets/logo.webp";
import nombre from "@/assets/nombre.webp";
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

export function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Detecta si la página ha hecho scroll
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 10);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Función para manejar el scroll suave y la redirección si es necesario
  const handleNavigation = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/", { replace: true }); // Navegar a la página principal
      setTimeout(() => {
        document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
      }, 300); // Pequeño retraso para esperar a que cargue la página
    } else {
      document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header
      className={`fixed top-0 left-0 right-0 w-full z-50 transition-all duration-300 ${scrolled ? "shadow-md bg-white" : "bg-background/95 "
        }`}
    >
      <div className="container flex h-16 items-center justify-between px-4 md:px-6 mx-auto">
        {/* Logo */}
        <div className="flex items-center gap-2">
          <img className="m-0 w-10" src={logo || "/placeholder.svg"} alt="Logo" />
          <img className="m-0 w-25" src={nombre || "/placeholder.svg"} alt="Nombre" />
        </div>

        {/* Menú para escritorio */}
        <nav className="hidden md:flex gap-6">
          <Link to="/" className="text-sm font-medium transition-colors hover:text-primary">
            Inicio
          </Link>
          <button onClick={() => handleNavigation("services")} className="text-sm font-medium transition-colors hover:text-primary">
            Servicios
          </button>
          <button onClick={() => handleNavigation("contact")} className="text-sm font-medium transition-colors hover:text-primary">
            Contacto
          </button>
          <Link to="/guardias" className="text-sm font-medium transition-colors hover:text-primary">
            Guardias
          </Link>
        </nav>

        {/* Botón del menú móvil */}
        <button
          name="menú" className="md:hidden p-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-800 transition"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      {/* Menú desplegable en móvil */}
      {isOpen && (
        <nav className="md:hidden absolute top-16 left-0 w-full bg-background shadow-lg border-t">
          <div className="flex flex-col p-4 gap-4">
            <button onClick={() => handleNavigation("home")} className="text-sm font-medium transition-colors hover:text-primary">
              Inicio
            </button>
            <button
              onClick={() => handleNavigation("services")}
              className="text-sm font-medium transition-colors hover:text-primary w-full text-left"
            >
              Servicios
            </button>
            <button
              onClick={() => handleNavigation("contact")}
              className="text-sm font-medium transition-colors hover:text-primary w-full text-left"
            >
              Contacto
            </button>
            <Link to="/guardias" className="text-sm font-medium transition-colors hover:text-primary">
              Guardias
            </Link>
          </div>
        </nav>
      )}

    </header>
  );
}
