import { Button } from "@/components/ui/button";
import { Facebook, Instagram } from "lucide-react";
import nombre from "@/assets/nombre.webp";
import { Link } from "react-router-dom";
export function Footer() {
    const sections = [
        { name: "Inicio", link: "#" },
        { name: "Servicios", link: "#services" },
        { name: "Sobre nosotros", link: "#about" },
        { name: "Contacto", link: "#contact" },
        { name: "Guardias", link: "/guardias", target: "_blank" },
    ];

    const legalLinks = [
        { name: "Aviso Legal", link: "#" },
        { name: "Política de Privacidad", link: "#" },
        { name: "Términos y condiciones", link: "#" },
        { name: "Política de cookies", link: "#" },
    ];

    const socialLinks = [
        {
            icon: Facebook,
            link: "https://www.facebook.com/farmaciapradadios/",
            label: "Facebook"
        },
        {
            icon: Instagram,
            link: "https://www.instagram.com/farmacia_prada_rianxo/",
            label: "Instagram"
        },
        {
            icon: () => (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16" style={{ width: "35px", height: "35px" }}>
                    <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                </svg>
            ),
            link: "#",
            label: "WhatsApp"
        }
    ];

    return (
        <footer className="w-full border-t bg-footer">
            <div className="container px-4 py-8 md:px-6 md:py-12 mx-auto max-w-full">
                <div className="grid gap-8 sm:grid-cols-2 md:grid-cols-3 justify-center text-center">

                    {/* Secciones */}
                    <div className="space-y-4">
                        <h3 className="text-lg font-bold text-white">Secciones</h3>
                        <ul className="space-y-2 text-sm">

                            {sections.map(({ name, link, target }, index) => (
                                <li key={index}>
                                    {name === "Guardias" ? (
                                        <Link to={link} target={target} className="text-footer-letra hover:text-primary">
                                            {name}
                                        </Link>
                                    ) : (
                                        <a href={link} target={target} className="text-footer-letra hover:text-primary">
                                            {name}
                                        </a>
                                    )}
                                </li>
                            ))}

                        </ul>
                    </div>

                    {/* Redes Sociales */}
                    <div className="space-y-4">
                        <div className="flex items-center justify-center">
                            <img className="w-50" src={nombre} alt="Logo de la empresa" />
                        </div>
                        <p className="text-sm text-footer-letra mb-6">
                            Síguenos en nuestras redes sociales para no perderte ninguna novedad.
                        </p>
                        <div className="flex gap-4 justify-center">
                            {socialLinks.map(({ icon: Icon, link, label }, index) => (
                                <Button
                                    key={index}
                                    variant="ghost"
                                    size="lg"
                                    className="rounded-full text-white hover:text-primary hover:bg-footer"

                                >
                                    <a href={link} target="_blank" rel="noopener noreferrer">
                                        <Icon style={{ width: "35px", height: "35px" }} aria-hidden="true" />
                                        <span className="sr-only">{label}</span>
                                    </a>
                                </Button>
                            ))}
                        </div>
                    </div>

                    {/* Legal */}
                    <div className="space-y-4">
                        <h3 className="text-lg font-bold text-white">Legal</h3>
                        <ul className="space-y-2 text-sm">
                            {legalLinks.map(({ name, link }, index) => (
                                <li key={index}>
                                    <a href={link} className="text-footer-letra hover:text-primary">
                                        {name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>

                <hr className="h-0.5 my-5" style={{ backgroundColor: "#AEAEAE" }} />
                <div className="mt-2 pt-4 flex flex-col sm:flex-row justify-center items-center gap-4">
                    <p className="text-sm text-footer-letra">
                        © {new Date().getFullYear()} Farmacia Prada. Todos los derechos reservados.
                    </p>
                </div>
            </div>
        </footer>
    );
}
