import { ScanFace, Heart, Droplet, Clipboard, Users, Clock, Anchor, ShoppingBag, MapPin } from "lucide-react";
import React from "react";

export function Services() {
  const services = [
    { icon: ScanFace, title: "Dermoanálisis facial", description: "Evaluación detallada de las características faciales para detectar el estado de salud de la persona." },
    { icon: Heart, title: "Análisis de colesterol y triglicéridos", description: "Pruebas para medir los niveles de colesterol y triglicéridos en sangre y evaluar riesgos cardiovasculares." },
    { icon: Droplet, title: "Análisis de ácido úrico", description: "Análisis para medir los niveles de ácido úrico y prevenir enfermedades como la gota." },
    { icon: Clipboard, title: "Análisis de hemoglobina glicada", description: "Examen clave para el control de la diabetes, midiendo el nivel promedio de glucosa en sangre." },
    { icon: Users, title: "Asesoramiento nutricional", description: "Consultas para diseñar planes de alimentación adecuados a tus necesidades y objetivos de salud." },
    { icon: Clock, title: "Confección de cestas infantiles a medida", description: "Cestas diseñadas especialmente para niños, adaptadas a sus necesidades de cuidado y salud." },
    { icon: Anchor, title: "Elaboración y revisión de botiquines marítimos", description: "Servicio especializado en la creación y revisión de botiquines de primeros auxilios para embarcaciones." },
    { icon: ShoppingBag, title: "Catálogo de dermocosmética, ortopedia, puericultura", description: "Ofrecemos una amplia gama de productos para el cuidado de la piel, ortopedia y cuidado infantil." },
    { icon: MapPin, title: "Envíos de parafarmacia a toda la península", description: "Envíos rápidos y seguros de productos de parafarmacia a cualquier lugar de la península." },
  ];

  return (
    <section id="services" className="w-full py-12 md:py-24 bg-muted">
      <div className="container px-4 md:px-6 mx-auto">
        <div className="text-center space-y-4">
          <div className="inline-block rounded-lg bg-background px-3 py-1 text-sm">Nuestros servicios</div>
          <h2 className="text-3xl font-bold tracking-tighter md:text-4xl">Estos son los servicios que ofrecemos</h2>
        </div>

        {/* Grid de Servicios */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-10">
          {services.map(({ icon: Icon, title, description }, index) => (
            <div key={index} className="flex flex-col items-center space-y-3 rounded-lg border bg-background p-6 text-center shadow-sm hover:shadow-md transition-shadow">
              <div className="rounded-full bg-primary/10 p-3">
                <Icon className="h-7 w-7 text-primary" aria-hidden="true" />
              </div>
              <h3 className="text-xl font-bold">{title}</h3>
              <p className="text-muted-foreground">{description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
