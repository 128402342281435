import { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine";

export function Map() {
    const [userPosition, setUserPosition] = useState(null);
    const markers = [{ geocode: [42.6503911, -8.8190925], popUp: "Farmacia Prada" }];

    const customIcon = new L.Icon({
        iconUrl: "Mark.webp",
        iconSize: [40, 40]
    });

    const customIconPerson = new L.Icon({
        iconUrl: "PersonMark.svg",
        iconSize: [40, 40]
    });
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setUserPosition([position.coords.latitude, position.coords.longitude]);
            });
        }
    }, []);

    const MapWithRouting = () => {
        const map = useMap();

        useEffect(() => {
            if (userPosition) {
                const routeControl = L.Routing.control({
                    waypoints: [
                        L.latLng(userPosition[0], userPosition[1]),
                        L.latLng(markers[0].geocode[0], markers[0].geocode[1])
                    ],
                    routeWhileDragging: false,
                    addWaypoints: false,
                    draggableWaypoints: false,
                    fitSelectedRoutes: true,
                    createMarker: () => null
                }).addTo(map);

                if (routeControl._container) {
                    routeControl._container.style.display = "none";
                }

                return () => map.removeControl(routeControl);
            }
        }, [map]);

        return null;
    };

    return (
        <MapContainer     attributionControl={false} center={[42.6503911, -8.8190925]} zoom={15} style={{ height: "400px", width: "100%" }}>
            <TileLayer
                attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {userPosition && (
                <Marker position={userPosition} icon={customIconPerson}>
                    <Popup>Tu ubicación</Popup>
                </Marker>
            )}
            {markers.map((marker, index) => (
                <Marker key={index} position={marker.geocode} icon={customIcon}>
                    <Popup>{marker.popUp}</Popup>
                </Marker>
            ))}
            <MapWithRouting />
        </MapContainer>
    );
}
